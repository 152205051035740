import Post from "../post/Post";
import Image from "../../assets/morganprofile.png";
import "./posts.scss";

const Posts = () => {
  //TEMPORARY
  const posts = [
    {
      id: 1,
      name: "Morgan F",
      userId: 1,
      profilePic:Image,
      desc: "Mum, I'm sorry it was in fact me who urinated on the kitchen floor all those years ago. It was wrong of me to blame our pet fish.",
      timePosted:"10 minutes ago"
    },
    {
      id: 2,
      name: "Morgan F",
      userId: 1,
      profilePic:Image,
      desc: "Dear Prince Mosi of Nigeria, I’m sorry for not responding to your email in 2009, I was shocked to hear of your bank troubles. I finally have that $2000 USD you asked for. I assume your original offer still stands to give me 20% of your country in return?",
      timePosted:"1 day ago"
    },
    {
      id: 3,
      name: "Morgan F",
      userId: 1,
      profilePic:Image,
      desc: "To the manufactures of Lava Lamps, I’m sorry for disregarding your warning. If you don't want people to try them, please avoid making them look so delicious.",
      timePosted:"3 days ago"
    },
    {
      id: 4,
      name: "Morgan F",
      userId: 1,
      profilePic:Image,
      desc: "Dear Homeless Man, I’m sorry I asked you “what’s for dinner?”. I was merely trying to make small talk while waiting for the bus. In no way was I having a dig at you.",
      timePosted:"1 week ago"
    },
    {
      id: 5,
      name: "Morgan F",
      userId: 1,
      profilePic:Image,
      desc: "To the people of the Potawatomi Resort and Casino, I’m sorry for my ignorance. I was unaware there were two types of Indian people when I tried to order a lamb korma from your restaurant.",
      timePosted:"1 week ago"
    },
    {
      id: 6,
      name: "Morgan F",
      userId: 1,
      profilePic:Image,
      desc: "Uncle Dan, I’m sorry for calling V8 super cars the signature hobby of domestic violence. I understand it's your passion, and it was wrong of me to correlate the two, especially considering your court case is still pending.",
      timePosted:"1 week ago"
    }
  ];

  return <div className="posts">
    {posts.map(post=>(
      <Post post={post} key={post.id}/>
    ))}
  </div>;
};

export default Posts;

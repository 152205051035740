import "./navbar.scss";
import InfoIcon from '@mui/icons-material/Info';
import React, { useState } from "react";
import { Popup } from "../popup/Popup";

const Navbar = () => {

  const [open, setOpen] = useState(false);

  return (
    <div className="navbar">
      <div className="left">
          <span>morganissorry.com</span>
      </div>
      <div className="right">
        <div>
          <button onClick={() => setOpen(true)} className="button">
            <InfoIcon/>
          </button>
          {open ? <Popup text="Clearly a joke and not a legal confession" closePopup={() => setOpen(false)} /> : null}
        </div>
      </div>
    </div>
  );
};


export default Navbar;
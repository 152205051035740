import "./post.scss";

const Post = ({ post }) => {

  //TEMPORARY
  const liked = false;

  return (
    <div className="post">
      <div className="container">
        <div className="user">
          <div className="userInfo">
            <img src={post.profilePic} alt="" />
            <div className="details">
                <span className="name">{post.name}</span>
              <span className="date">{post.timePosted}</span>
            </div>
          </div>
        </div>
        <div className="content">
          <p>{post.desc}</p>
          <img src={post.img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Post;

import React from "react";
import "./popup.scss";
import CloseIcon from '@mui/icons-material/Close';

export const Popup = ({ text, closePopup }) => {
  return (
    <div className="popup-container">
     <div className="popup-body">
      <h4>{text}</h4>
      <button onClick={closePopup} className="closeButton">
        <CloseIcon />
      </button>
     </div>
    </div>
  );
};